import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { Link } from "react-router-dom";

function Orders() {
  return (
    <>
      <Helmet>
        <title>Historial de pedidos - {website_name}</title>
      </Helmet>

      <div className="account-main-header">Historial de pedidos</div>
      <div className="account-main-content">
        <p>Todavía no has realizado ningún pedido.</p>
        <Link to="/" className="button">
          Volver a la tienda
        </Link>
      </div>
    </>
  );
}
export default Orders;
