import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/common/Layout";
import Home from "./components/pages/Home";
import "./assets/styles/app.css";
import ProductPage from "./components/pages/ProductPage";
import Search from "./components/pages/Search";
import Music from "./components/pages/Music";
import Merch from "./components/pages/Merch";
import NoFound from "./components/pages/NoFound";
import CollectionsAll from "./components/pages/CollectionsAll";
import { ShoppingCartProvider } from "./context/ShoppingCartContext";
import Account from "./components/pages/Account";
import { useAuth } from "./firebase/fbQueries";
import { db } from "./firebase/config";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Orders from "./components/pages/Orders";
import Profile from "./components/pages/Profile";
import Logout from "./components/pages/Logout";
import Addresses from "./components/pages/Addresses";
import Check from "./components/pages/Check";
import Collections from "./components/pages/Collections";
import Newsletter from "./components/pages/Newsletter";

function App() {
  const authUser = useAuth(db);

  return (
    <ShoppingCartProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<Search />} />
            <Route path="/collections" element={<CollectionsAll />} />
            <Route path="/collections/merch" element={<Merch />} />
            <Route path="/collections/music" element={<Music />} />
            <Route path="/collections/:collection" element={<Collections />} />
            <Route path="/products/:path" element={<ProductPage />} />

            {!authUser ? (
              <>
                <Route
                  path="/account"
                  element={<Navigate to="/account/login" />}
                />
                <Route
                  path="/account/orders"
                  element={<Navigate to="/account/login" />}
                />
                <Route
                  path="/account/addresses"
                  element={<Navigate to="/account/login" />}
                />
                <Route
                  path="/account/profile"
                  element={<Navigate to="/account/login" />}
                />
              </>
            ) : (
              <>
                <Route
                  path="/account/login"
                  element={<Navigate to="/account" replace />}
                />
                <Route
                  path="/account/register"
                  element={<Navigate to="/account" replace />}
                />
              </>
            )}

            <Route element={<Account />}>
              <Route
                path="/account"
                element={<Navigate to="/account/orders" replace />}
              />
              <Route path="/account/orders" element={<Orders />} />
              <Route path="/account/addresses" element={<Addresses />} />
              <Route path="/account/profile" element={<Profile />} />
              <Route path="/account/newsletter" element={<Newsletter />} />
              <Route path="/account/logout" element={<Logout />} />
            </Route>
            <Route path="/account/check" element={<Check />} />
            <Route path="/account/login" element={<Login />} />
            <Route path="/account/register" element={<Register />} />

            <Route path="/pages/faqs" element={<Home />} />
            <Route path="/pages/contact" element={<Home />} />
            <Route path="/pages/about" element={<Home />} />
            <Route path="/pages/contact" element={<Home />} />

            <Route path="/policies/refund-policy" element={<Home />} />
            <Route path="/policies/privacy-policy" element={<Home />} />
            <Route path="/policies/terms-of-service" element={<Home />} />

            <Route path="*" element={<NoFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ShoppingCartProvider>
  );
}

export default App;
