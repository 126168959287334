import { useEffect, useState } from "react";
import Logo from "./Logo";
import NavButtons from "./NavButtons";
import NavSubMenu from "./NavSubMenu";

function Header(props) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("none");

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollDirection(currentPosition > scrollPosition ? "down" : "up");
      setScrollPosition(currentPosition);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollPosition]);

  const headerStyle = {
    top: scrollDirection === "down" ? "0" : "30px",
  };

  return (
    <header className="header" style={headerStyle}>
      <div className="container">
        <div className="flex">
          <nav className="nav-menu">
            <ul className="nav-list">
              <NavSubMenu menu={props.menu} handleMenu={props.handleMenu} />
            </ul>
          </nav>
        </div>
        <div className="flex">
          <Logo />
        </div>
        <div className="flex">
          <NavButtons handleCart={props.handleCart} />
        </div>
      </div>
    </header>
  );
}
export default Header;
