import { useEffect } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/config";
import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    const logoutUser = async () => {
      try {
        await signOut(auth);
        navigate("/account/login", {
          state: { message: "Sesión cerrada correctamente." },
        });
      } catch (error) {
        console.error("Error al cerrar sesión:", error);
        navigate("/account", {
          state: { message: "Hubo un error al cerrar la sesión." },
        });
      }
    };

    logoutUser();
  }, [navigate]);

  return (
    <section className="section">
      <div className="container">
        <p>Cerrando sesión...</p>
        <Helmet>
          <title>Cerrando sesión - {website_name}</title>
        </Helmet>
      </div>
    </section>
  );
}
export default Logout;
