import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  useCollectionMerch,
  useCollectionMusic,
  useNavCollections,
  useProducts,
} from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function NavMenu(props) {
  const [openCollection, setOpenCollection] = useState(false);
  const [openMerch, setOpenMerch] = useState(false);
  const [openMusic, setOpenMusic] = useState(false);

  const handleCollection = () => {
    setOpenCollection(!openCollection);
  };

  const handleMerch = () => {
    setOpenMerch(!openMerch);
  };

  const handleMusic = () => {
    setOpenMusic(!openMusic);
  };

  const products = useProducts(db);
  const collections = useNavCollections(db);
  const merch = useCollectionMerch(db);
  const music = useCollectionMusic(db);

  const countProductsByCategory = (category) => {
    return products.filter(
      (product) => product.category.includes(category) && product.show
    ).length;
  };

  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li className="nav-item">
          <div className="nav-wrapper">
            <Link to="/" className="nav-link" onClick={() => props.close()}>
              Inicio
            </Link>
          </div>
        </li>
        {/* Collections */}
        <li className="nav-item">
          <div className="nav-wrapper">
            <Link
              to="/collections"
              className="nav-link"
              onClick={() => props.close()}
            >
              Colecciones
            </Link>
            <button
              type="button"
              className="button icon-button"
              onClick={handleCollection}
            >
              <FontAwesomeIcon
                icon={!openCollection ? faAngleDown : faAngleUp}
              />
            </button>
          </div>
          <ul
            className={!openCollection ? "nav-sub-list" : "nav-sub-list show"}
          >
            {collections
              .sort((a, b) => a.order - b.order)
              .map(
                (collection, index) =>
                  collection.show && (
                    <li key={index} className="nav-sub-item">
                      <Link
                        to={`/collections/${collection.path}`}
                        className="nav-sub-link"
                        onClick={() => props.close()}
                      >
                        {collection.title}
                      </Link>
                    </li>
                  )
              )}
          </ul>
        </li>
        {/* Merch */}
        <li className="nav-item">
          <div className="nav-wrapper">
            <Link
              to="/collections/merch"
              className="nav-link"
              onClick={() => props.close()}
            >
              Ropa
            </Link>
            <button
              type="button"
              className="button icon-button"
              onClick={handleMerch}
            >
              <FontAwesomeIcon icon={!openMerch ? faAngleDown : faAngleUp} />
            </button>
          </div>
          <ul className={!openMerch ? "nav-sub-list" : "nav-sub-list show"}>
            {merch
              .sort((a, b) => a.order - b.order)
              .map((merch, index) => (
                <li key={index} className="nav-sub-item">
                  <Link
                    to={`/collections/${merch.path}`}
                    className="nav-sub-link"
                    onClick={() => props.close()}
                  >
                    {merch.title}
                    <span className="nav-sub-count">
                      {countProductsByCategory(merch.path)}
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </li>
        {/* Music */}
        <li className="nav-item">
          <div className="nav-wrapper">
            <Link
              to="/collections/music"
              className="nav-link"
              onClick={() => props.close()}
            >
              Música
            </Link>
            <button
              type="button"
              className="button icon-button"
              onClick={handleMusic}
            >
              <FontAwesomeIcon icon={!openMusic ? faAngleDown : faAngleUp} />
            </button>
          </div>
          <ul className={!openMusic ? "nav-sub-list" : "nav-sub-list show"}>
            {music
              .sort((a, b) => a.order - b.order)
              .map((music, index) => (
                <li key={index} className="nav-sub-item">
                  <Link
                    to={`/collections/${music.path}`}
                    className="nav-sub-link"
                    onClick={() => props.close()}
                  >
                    {music.title}
                    <span className="nav-sub-count">
                      {countProductsByCategory(music.path)}
                    </span>
                  </Link>
                </li>
              ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
