import "../../assets/styles/layout.css";
import { useEffect, useState } from "react";
import Header from "./Header";
import Menu from "./Menu";
import Cart from "./Cart";
import Footer from "./Footer";
import Main from "./Main";
import Glass from "./Glass";
import TopBar from "./TopBar";
import ScrollToTop from "./ScrollToTop";
import Login from "./Login";

function Layout() {
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
    setGlass(!glass);
  };

  const [cart, setCart] = useState(false);

  const handleCart = () => {
    setCart(!cart);
    setGlass(!glass);
  };

  const [glass, setGlass] = useState(false);

  const handleGlass = () => {
    setGlass(!glass);
    setMenu(false);
    setCart(false);
    setLogin(false);
  };

  const [login, setLogin] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "F2") {
        setLogin(!login);
        setGlass(!glass);
      }

      if (event.code === "KeyM" && event.altKey) {
        handleMenu();
      }
      
      if (event.code === "KeyC" && event.altKey) {
        handleCart();
      }

    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  return (
    <div className={!menu && !cart ? "app" : "app no-scroll"}>
      <ScrollToTop />
      <TopBar />
      <Header
        menu={menu}
        handleMenu={handleMenu}
        cart={cart}
        handleCart={handleCart}
      />
      <Menu menu={menu} handleMenu={handleMenu} />
      <Cart cart={cart} handleCart={handleCart} />
      <Main />
      <Footer />
      <Glass glass={glass} handleGlass={handleGlass} />
      <Login login={login} setLogin={setLogin} setGlass={setGlass} />
    </div>
  );
}
export default Layout;
