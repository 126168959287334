import { useEffect, useState } from "react";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

// ---- ---- auth ---- ---- //
export function useAuth(db) {
  const [authUser, setAuthUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
    });

    return () => unsubscribe();
  }, [db]);

  return authUser;
}

// ---- ---- music ---- ---- //
export function useMusic(db) {
  const [music, setMusic] = useState([]);

  useEffect(() => {
    const musicRef = collection(db, "music");

    const unsubscribe = onSnapshot(musicRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMusic(data);
    });

    return () => unsubscribe();
  }, [db]);

  return music;
}

// ---- ---- products ---- ---- //
export function useProducts(db) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const productsRef = collection(db, "products");

    const unsubscribe = onSnapshot(productsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setProducts(data);
    });

    return () => unsubscribe();
  }, [db]);

  return products;
}

// ---- ---- usuarios ---- ---- //
export function useUsers(db) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const ref = collection(db, "users");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setUsers(data);
    });

    return () => unsubscribe();
  }, [db]);

  return users;
}

// ---- ---- usuarios ---- ---- //
export function useFans(db) {
  const [fans, setFans] = useState([]);

  useEffect(() => {
    const ref = collection(db, "fans");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setFans(data);
    });

    return () => unsubscribe();
  }, [db]);

  return fans;
}

// ---- ---- messages ---- ---- //
export function useMessages(db) {
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const ref = collection(db, "messages");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMessages(data);
    });

    return () => unsubscribe();
  }, [db]);

  return messages;
}

// ---- ---- Consulta de Colecciones ---- ---- //
export function useCollections(db) {
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    const ref = collection(db, "collections");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setCollections(data);
    });

    return () => unsubscribe();
  }, [db]);

  return collections;
}

// ---- ---- Consulta de Colecciones ---- ---- //
export function useNavCollections(db) {
  const [navCollections, setNavCollections] = useState([]);

  useEffect(() => {
    const ref = collection(db, "collection-collections");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setNavCollections(data);
    });

    return () => unsubscribe();
  }, [db]);

  return navCollections;
}

// ---- ---- Consulta de Colecciones ---- ---- //
export function useCollectionMerch(db) {
  const [CollectionMerch, setCollectionMerch] = useState([]);

  useEffect(() => {
    const ref = collection(db, "collection-merch");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setCollectionMerch(data);
    });

    return () => unsubscribe();
  }, [db]);

  return CollectionMerch;
}

// ---- ---- Consulta de Colecciones ---- ---- //
export function useCollectionMusic(db) {
  const [CollectionMusic, setCollectionMusic] = useState([]);

  useEffect(() => {
    const ref = collection(db, "collection-music");

    const unsubscribe = onSnapshot(ref, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setCollectionMusic(data);
    });

    return () => unsubscribe();
  }, [db]);

  return CollectionMusic;
}

// ---- ---- settings store ---- ---- //
export function useConfigStore(db) {
  const [configStore, setConfigStore] = useState([]);

  useEffect(() => {
    const configStoreRef = doc(db, "settings", "store");

    const unsubscribe = onSnapshot(configStoreRef, (doc) => {
      if (doc.exists()) {
        setConfigStore({ ...doc.data(), id: doc.id });
      } else {
        console.log("No such document!");
      }
    });
    return () => unsubscribe();
  }, [db]);

  return configStore;
}
