import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useParams } from "react-router-dom";
import { useCollections, useProducts } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";
import NavRoutes from "../common/NavRoutes";

function Collections() {
  const { collection } = useParams();

  const products = useProducts(db);
  const collections = useCollections(db);

  // Asegurarse de que los productos están cargados antes de filtrar
  if (!products || products.length === 0) {
    return (
      <div
        style={{
          gridColumn: "span 12",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          letterSpacing: 1 + "px",
          font: "normal 12px var(--font-hand)",
        }}
      >
        Cargando...
      </div>
    );
  }

  // Filtrar productos que pertenecen a la colección especifica en el path
  const sameProducts = products.filter(
    (product) =>
      Array.isArray(product.collections) &&
      product.collections.includes(collection)
  );

  const page = collections.find((coll) => coll.path === collection);

  return (
    <>
      <Helmet>
        <title>
          {page?.title ? page.title : collection} - {website_name}
        </title>
      </Helmet>

      <section className="section">
        <div className="container">
          <NavRoutes category={page?.title ? page.title : collection} />
          <div className="block-header">
            <h2>{page?.title ? page.title : collection}</h2>
          </div>
          <div className="block-grid">
            {sameProducts.length > 0 ? (
              sameProducts.map((product, index) => (
                <ProductCard key={index} {...product} />
              ))
            ) : (
              <div
                style={{
                  gridColumn: "span 12",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  letterSpacing: 1 + "px",
                  font: "normal 12px var(--font-hand)",
                }}
              >
                <p>
                  No se han encontrado productos en la colección
                  <b>{` "${collection}"`}</b>.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Collections;
