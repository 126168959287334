import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useProducts } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";
import NavRoutes from "../common/NavRoutes";

function Merch() {
  const category = "merch";
  const products = useProducts(db);
  const sameCategory = products.filter((product) =>
    product.category.includes(category)
  );
  return (
    <>
      <Helmet>
        <title>Mercancía - {website_name}</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <NavRoutes category="Mercancía" />
          <div className="block-header">
            <h2>Mercancía</h2>
          </div>
          <div className="block-grid">
            {sameCategory
              .sort((a, b) => a.id - b.id)
              .map((product) => (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  show_product={product.show_product}
                  show_dto={product.show_dto}
                  show_pre={product.show_pre}
                  show_sot={product.show_sot}
                  img_def={product.img_def}
                  img_hov={product.img_hov}
                  title={product.title}
                  price={product.price}
                  until={product.until}
                  link={product.link}
                  path={product.path}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default Merch;
