import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useProducts } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";
import { Link } from "react-router-dom";

function Home() {
  const products = useProducts(db);

  // const categoryMusic = "music";
  // const sameCategoryMusic = products.filter((product) =>
  //   product.category.includes(categoryMusic)
  // );

  return (
    <>
      <Helmet>
        <title>{website_name}</title>
      </Helmet>
      <section className="section">
        <div className="banner"></div>
      </section>
      <section className="section">
        <div className="container">
          <div className="block-grid">
            {products
              .sort((a, b) => a.id - b.id)
              .map((product, index) => (
                <ProductCard key={index} {...product} />
              ))}
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="media-block">
            <picture>
              <img
                src="//shop.justinbiebermusic.com/cdn/shop/files/Tile-Merch-BW_cb163c29-beeb-4915-a63b-166077dfc68f.jpg?v=1668038723&width=900"
                alt=""
              />
            </picture>
            <h2>Ropa</h2>
            <Link to="/collections/merch">Ropa</Link>
          </div>
          <div className="media-block">
            <picture>
              <img
                src="//shop.justinbiebermusic.com/cdn/shop/files/Tile-Music-BW.jpg?v=1668038724&width=900"
                alt=""
              />
            </picture>
            <h2>Música</h2>
            <Link to="/collections/music">Música</Link>
          </div>
        </div>
      </section>
    </>
  );
}
export default Home;
