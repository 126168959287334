import { faImage, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faAdd, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { CartContext } from "../../context/ShoppingCartContext";
// import { useProducts } from "../../firebase/fbQueries";
// import { db } from "../../firebase/config";

function ProductItem(props) {
  const [cart, setCart] = useContext(CartContext);

  // const products = useProducts(db);

  const addToCart = () => {
    setCart((currItems) => {
      const isItemsFound = currItems.find((item) => item.id === props.id);
      if (isItemsFound) {
        return currItems.map((item) => {
          if (item.id === props.id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      } else {
        return [
          ...currItems,
          {
            id: props.id,
            quantity: 1,
            img_def: props.img_def,
            title: props.title,
            price: props.price,
          },
        ];
      }
    });
  };

  const removeItem = (id, color, size) => {
    setCart((currItems) => {
      if (
        currItems.find(
          (item) => item.id === id && item.color === color && item.size === size
        )?.quantity === 1
      ) {
        return currItems.filter(
          (item) => item.id !== id || item.color !== color || item.size !== size
        );
      } else {
        return currItems.map((item) => {
          if (
            item.id === props.id &&
            item.color === color &&
            item.size === size
          ) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  };

  const removeAll = (id) => {
    setCart((currItems) => currItems.filter((item) => item.id !== id));
  };

  const getQuantityById = (id) => {
    return cart.find((item) => item.id === id)?.quantity || 0;
  };

  const quantityPerItem = getQuantityById(props.id);

  return (
    <div className="product-item">
      <div className="product-item-wrapper">
        <div className="product-item-picture" title={"Color: " + props.color}>
          {props.gallery ? (
            <img
              src={props.gallery[0]}
              alt={props.title}
              title={"Color: " + props.color}
              loading="lazy"
            />
          ) : (
            <>
              <FontAwesomeIcon icon={faImage} size="2xl" />
            </>
          )}
        </div>
        <div className="product-item-details">
          <span className="product-item-title" title={props.title}>
            {props.title}
          </span>
          <span className="product-item-price">
            ${props.price.toFixed(2)} USD
            {}
            {props.size && <> - {props.size}</>}
          </span>
          <div className="product-item-control">
            <button
              type="button"
              className="button icon-button"
              onClick={() => removeItem()}
              title="Remover"
            >
              <FontAwesomeIcon icon={faMinus} size="lg" />
            </button>
            <span className="screen">{quantityPerItem}</span>
            <button
              type="button"
              className="button icon-button"
              onClick={() => addToCart()}
              title="Agregar"
            >
              <FontAwesomeIcon icon={faAdd} size="lg" />
            </button>
            <button
              type="button"
              className="button icon-button"
              onClick={() => removeAll(props.id)}
              title="Eliminar producto"
            >
              <FontAwesomeIcon icon={faTrashCan} size="lg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductItem;
