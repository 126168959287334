import {
  faBagShopping,
  faSearch,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { CartContext } from "../../context/ShoppingCartContext";
import { useNavigate } from "react-router-dom";

function NavButtons(props) {
  const navigate = useNavigate();

  const [cart] = useContext(CartContext);

  const quantity = cart.reduce((acc, curr) => {
    return acc + curr.quantity;
  }, 0);

  const goToSearch = () => {
    navigate("/search");
  };

  const goToAccount = () => {
    navigate("/account");
  };

  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li hidden>
          <button
            type="button"
            className="button icon-button"
            onClick={() => goToSearch()}
            title="Busqueda"
            aria-label="Buscar productos"
          >
            <FontAwesomeIcon icon={faSearch} size="xl" />
          </button>
        </li>
        <li>
          <button
            type="button"
            className="button icon-button"
            onClick={() => goToAccount()}
            title="Cuenta"
            aria-label="Ir a la cuenta"
          >
            <FontAwesomeIcon icon={faUser} size="xl" />
          </button>
        </li>
        <li>
          <button
            type="button"
            className="button icon-button"
            onClick={props.handleCart}
            title="Bolsa de la Compra"
            aria-label="Abrir bolsa de la compra"
          >
            <FontAwesomeIcon icon={faBagShopping} size="xl" />
            {quantity > 0 && <span className="badge">{quantity}</span>}
          </button>
        </li>
      </ul>
    </nav>
  );
}
export default NavButtons;
