import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CartContext } from "../../context/ShoppingCartContext";
import { useConfigStore } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { useContext } from "react";

import ProductItem from "./ProductItem";

function Cart(props) {
  const configStore = useConfigStore(db);

  const [cart, setCart] = useContext(CartContext);

  const subtotal = cart.reduce(
    (acc, curr) => acc + curr.quantity * curr.price,
    0
  );

  const checkOut = () => {
    alert("¡Lo siento, en este momento no se pueden realizar compras!");
    // console.log(cart);
  };

  return (
    <div className={!props.cart ? "bag" : "bag show"} aria-label={setCart}>
      {cart.length > 0 ? (
        <>
          <div className="bag-header">
            <span>Bolsa de la Compra</span>
          </div>
          <div className="bag-content">
            <div className="bag-main">
              {configStore.show_promo && (
                <div className="promo">
                  <span className="promo-text">
                    0.{configStore.cart_promo}% dto. en más de{" "}
                    {configStore.cart_max} productos
                  </span>
                  <input
                    type="range"
                    name=""
                    id=""
                    title=""
                    value={cart.length}
                    max={configStore.cart_max}
                    min={0}
                  />
                </div>
              )}
              {cart.map((product, index) => (
                <ProductItem key={index} {...product} />
              ))}
            </div>
            <div className="bag-control">
              <div className="wrapper">
                <div className="subtotal">
                  <span>Subtotal</span>
                  <span>${subtotal.toFixed(2)}</span>
                </div>
                <button
                  type="button"
                  className="button primary-button"
                  onClick={() => checkOut()}
                >
                  Comprobar
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          style={{
            width: 100 + "%",
            height: 100 + "vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textTransform: "lowercase",
            letterSpacing: 1 + "px",
            font: "normal 12px var(--font-hand)",
          }}
        >
          Su carrito está vacío...
        </div>
      )}
    </div>
  );
}
export default Cart;
