import { Link, Outlet } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faEnvelope,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import {
  faClockRotateLeft,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Account() {
  const users = useUsers(db);
  const authUser = useAuth(db);
  const currUser = users.find((user) => user.email === authUser.email);

  return (
    <>
      {authUser ? (
        <>
          <section className="section">
            <div className="container">
              <div className="account-page">
                <div className="account-menu">
                  <div className="current-account">
                    <div>
                      <small>Bienvenido,</small>
                      <br />
                      {currUser ? (
                        <span>{`${currUser.firstName} ${currUser.lastName}`}</span>
                      ) : (
                        <span>Nombre de Usuario</span>
                      )}
                    </div>
                  </div>
                  <nav className="nav-menu">
                    <ul className="nav-list">
                      <li className="nav-item">
                        <Link to="/account/orders" className="nav-link">
                          <FontAwesomeIcon icon={faClockRotateLeft} />
                          <span className="large">Historial de pedidos</span>
                          <span className="short">Pedidos</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account/addresses" className="nav-link">
                          <FontAwesomeIcon icon={faAddressBook} />
                          <span className="large">Libreta de direcciones</span>
                          <span className="short">Direcciones</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account/profile" className="nav-link">
                          <FontAwesomeIcon icon={faUser} />
                          <span className="large">Perfil</span>
                          <span className="short">Perfil</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account/newsletter" className="nav-link">
                          <FontAwesomeIcon icon={faEnvelope} />
                          <span className="large">Boletín</span>
                          <span className="short">Boletín</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/account/logout" className="nav-link">
                          <FontAwesomeIcon icon={faSignOut} />
                          <span className="large">Cerrar sesión</span>
                          <span className="short">Cerrar sesión</span>
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="account-main">
                  <Outlet />
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <>Sorry</>
      )}
    </>
  );
}
export default Account;
