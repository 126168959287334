import Copyright from "./Copyright";
import NewsLetter from "./Newsletter";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <NewsLetter />
        <hr />
        <Copyright />
      </div>
    </footer>
  );
}
export default Footer;
