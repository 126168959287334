import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays, faPercent } from "@fortawesome/free-solid-svg-icons";
import { useMusic, useProducts } from "../../firebase/fbQueries";
import { CartContext } from "../../context/ShoppingCartContext";
import { website_name } from "../../config/websiteConfig";
import ProductCard from "../common/ProductCard";
import { useContext, useState } from "react";
import NoFound from "./NoFound";

function Product() {
  const { path } = useParams();

  const music = useMusic(db);
  const products = useProducts(db);

  const productSelected = products.find((product) => product.path === path);

  const songs = music.find((list) => list.path === productSelected.songs);

  const htmlStringDesc = productSelected ? productSelected.desc : "";
  const htmlStringInfo = productSelected ? productSelected.info : "";

  let link = productSelected ? productSelected.link : "";
  if (link && link.startsWith("https://store.danielospid.com/products/")) {
    link = link.substring("https://store.danielospid.com/products/".length);
  }

  const [setCart] = useContext(CartContext);

  const addToCart = () => {
    setCart((currItems) => {
      const isItemsFound = currItems.find(
        (item) =>
          item.id ===
            productSelected.id + price + sizeSelected + colorSelected &&
          item.price === price &&
          item.size === sizeSelected &&
          item.colorSelected === colorSelected
      );
      if (isItemsFound) {
        return currItems.map((item) => {
          if (
            item.id ===
              productSelected.id + price + sizeSelected + colorSelected &&
            item.price === price &&
            item.size === sizeSelected &&
            item.color === colorSelected
          ) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      } else {
        return [
          ...currItems,
          {
            id: productSelected.id + price + sizeSelected + colorSelected,
            quantity: 1,
            image:
              photoSelected === null
                ? productSelected.gallery[0]
                : photoSelected,
            title: productSelected.title,
            price: price === null ? productSelected.price : price,
            size: sizeSelected,
            color: colorSelected,
          },
        ];
      }
    });
  };

  // const removeItem = (id) => {
  //   setCart((currItems) => {
  //     if (
  //       currItems.find((item) => item.id === productSelected.id)?.quantity === 1
  //     ) {
  //       return currItems.filter((item) => item.id !== productSelected.id);
  //     } else {
  //       return currItems.map((item) => {
  //         if (item.id === productSelected.id) {
  //           return { ...item, quantity: item.quantity - 1 };
  //         } else {
  //           return item;
  //         }
  //       });
  //     }
  //   });
  // };

  const [photoSelected, setPhotoSelected] = useState(null);

  // función para cambiar la imagen del producto
  const changePhoto = (photo, index) => {
    setColor(index);
    setPhotoSelected(photo);
  };

  const [size, setSize] = useState(null);
  const [sizeSelected, setSizeSelected] = useState("s");

  // función para cambiar la talla del producto
  const changeSize = (sizeName, index) => {
    setSize(index);
    setSizeSelected(sizeName);
  };

  const [color, setColor] = useState(0);
  const [colorSelected, setColorSelected] = useState("Negro");

  // función para cambiar el color del producto
  const changeSelected = (colorName, id) => {
    setColor(id);
    setColorSelected(colorName);
    setPhotoSelected(productSelected.gallery[id]);
  };

  const [price, setPrice] = useState(null);
  const [priceSelected, setPriceSelected] = useState(0);

  const changePrice = (price, index) => {
    setPrice(price);
    setPriceSelected(index);
  };

  // Filtrar productos de la misma categoría y omitir el producto seleccionado.
  const sameProducts = products.filter(
    (product) =>
      product.path !== path &&
      Array.isArray(product.category) &&
      product.category.some((cat) => productSelected.category.includes(cat))
  );

  return (
    <>
      {productSelected ? (
        productSelected.show ? (
          <div className="section">
            <Helmet>
              <title>
                {productSelected.title} - {website_name}
              </title>
              <meta name="description" content={productSelected.desc} />
              <meta property="og:title" content={productSelected.title} />
              <meta property="og:description" content={productSelected.desc} />
              <meta
                property="og:url"
                content={
                  "https://danielospid.com/products/" + productSelected.path
                }
              />
              <meta property="og:image" content={productSelected.img_def} />
              <meta property="twitter:card" content="summary" />
              <meta property="twitter:title" content={productSelected.title} />
              <meta
                property="twitter:description"
                content={productSelected.desc}
              />
              <meta
                property="twitter:image"
                content={productSelected.img_def}
              />
            </Helmet>
            <div className="container">
              <div className="product">
                <div className="product-pictures">
                  <div className="product-pictures-wrapper">
                    {!photoSelected && (
                      <img
                        src={productSelected.img_def}
                        alt={productSelected.title}
                        className="product-pictures-image"
                      />
                    )}
                    {photoSelected && (
                      <img
                        src={photoSelected}
                        alt={productSelected.title}
                        className="product-pictures-image"
                      />
                    )}
                  </div>
                  {productSelected.gallery && (
                    <div className="product-gallery">
                      {productSelected.gallery.map((photo, index) => (
                        <div
                          className="product-photo"
                          key={index}
                          onClick={() => changePhoto(photo, index)}
                        >
                          <img src={photo} alt={productSelected.title} />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="product-details">
                  {/* title & price */}
                  <div className="product-details-column">
                    {/* title */}
                    <h2>{productSelected.title}</h2>
                    {/* price */}
                    <div className="product-details-price">
                      {productSelected.show_dto ? (
                        <>
                          <span className="price-new">
                            {"$"}
                            {productSelected.promo_price.toFixed(2)}
                            {" USD"}
                          </span>
                          <span className="price-old">
                            {"$"}
                            {productSelected.price.toFixed(2)}
                            {" USD"}
                          </span>
                        </>
                      ) : (
                        <>
                          {!price && (
                            <span className="price">
                              {"$"}
                              {productSelected.price.toFixed(2)}
                              {" USD"}
                            </span>
                          )}
                          {price && (
                            <span className="price">
                              {"$"}
                              {price.toFixed(2)}
                              {" USD"}
                            </span>
                          )}
                        </>
                      )}
                      <div className="row-tags">
                        {productSelected.presale && (
                          <span className="presale">
                            <FontAwesomeIcon icon={faCalendarDays} />
                            Preventa
                          </span>
                        )}
                        {productSelected.dto && (
                          <span className="dto">
                            <FontAwesomeIcon icon={faPercent} />
                            Descuento
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {productSelected.prices && (
                    <div className="product-details-column">
                      <h3>Precios</h3>
                      <div className="product-details-row">
                        {productSelected.prices.map((item, index) => (
                          <input
                            key={index}
                            type="button"
                            value={`$${item.toFixed(2)}`}
                            className={
                              priceSelected === index
                                ? "button option-button selected"
                                : "button option-button"
                            }
                            onClick={() => changePrice(item, index)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {/* btn */}
                  <div className="product-details-column">
                    <div className="product-details-row">
                      {productSelected.stock === 0 || productSelected.sold ? (
                        <button
                          type="button"
                          className="button primary-button disabled"
                          disabled
                        >
                          Agotado
                        </button>
                      ) : productSelected.presale ? (
                        <button
                          type="button"
                          className="button primary-button"
                          // onClick={() => addToCart()}
                        >
                          Reservar
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="button primary-button"
                          onClick={() => addToCart()}
                        >
                          Agregar al Carrito
                        </button>
                      )}
                    </div>
                  </div>
                  {productSelected.sizes && (
                    <div className="product-details-column">
                      <h3>Talla{productSelected.sizes.length > 1 && "s"}</h3>
                      <div className="product-details-row">
                        {productSelected.sizes.map((item, index) => (
                          <button
                            key={index}
                            type="button"
                            value={item}
                            className={
                              size === index
                                ? "button option-button selected"
                                : "button option-button"
                            }
                            onClick={() => changeSize(item, index)}
                          >
                            {item}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                  {productSelected.colors && (
                    <div className="product-details-column">
                      <h3>Color{productSelected.sizes.length > 1 && "es"}</h3>
                      <div className="product-details-row">
                        {productSelected.colors.map((item, index) => (
                          <input
                            key={index}
                            type="button"
                            value={item}
                            className={
                              color === index
                                ? "button option-button selected"
                                : "button option-button"
                            }
                            onClick={() => changeSelected(item, index)}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                  {/* songs */}
                  {songs && (
                    <div className="product-details-column">
                      <h3>Lista de canciones {songs.length > 1 ? "s" : ""}</h3>
                      <ol>
                        {songs.songs.map((song, index) => (
                          <li key={index}>{song}</li>
                        ))}
                      </ol>
                    </div>
                  )}
                  {/* desc */}
                  {productSelected.desc && (
                    <div className="product-details-column">
                      <h3>Descripción</h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: htmlStringDesc }}
                      ></p>
                    </div>
                  )}
                  {/* info */}
                  {productSelected.info && (
                    <div className="product-details-column">
                      <h3>Información de prensado</h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: htmlStringInfo }}
                      ></p>
                    </div>
                  )}
                </div>
              </div>
              {sameProducts.length > 1 && (
                <>
                  <br />
                  <hr />
                  <br />
                  <br />
                  <div className="block-grid">
                    {sameProducts
                      .sort((a, b) => a.id - b.id)
                      .slice(-20)
                      .map((product, index) => (
                        <ProductCard key={index} {...product} />
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        ) : (
          <NoFound />
        )
      ) : (
        <div className="product-load">El producto se está cangando...</div>
      )}
    </>
  );
}
export default Product;
