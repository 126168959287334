import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useProducts } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";

function Search() {
  const products = useProducts(db);
  const { search } = useLocation();
  const { q = "" } = queryString.parse(search);
  const [searchTerm, setSearchTerm] = useState(q);
  const sameTitleProducts = products.filter((product) => {
    const matchesSearch =
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.price.toString().includes(searchTerm.toString()) ||
      product.category.includes(searchTerm);
    return matchesSearch;
  });

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?q=${searchTerm}`);
  };

  return (
    <>
      <Helmet>
        <title>Busqueda - {website_name}</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="banner-search">
            <div className="background-image"></div>
            <div className="blur"></div>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="Busqueda de productos"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <button type="submit">Buscar</button>
            </form>
          </div>
          <div className="block-grid">
            {sameTitleProducts
              .sort((a, b) => a.id - b.id)
              .map((product) => (
                <ProductCard
                  key={product.id}
                  id={product.id}
                  category={product.category}
                  show_product={product.show_product}
                  show_dto={product.show_dto}
                  show_pre={product.show_pre}
                  show_sot={product.show_sot}
                  img_def={product.img_def}
                  img_hov={product.img_hov}
                  title={product.title}
                  price={product.price}
                  until={product.until}
                  link={product.link}
                  path={product.path}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default Search;
