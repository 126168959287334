import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { website_name } from "../../config/websiteConfig";
import { auth, db, sendEmailVerification } from "../../firebase/config";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

function Register() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { firstName, lastName, email, password } = data;

    try {
      // Crear usuario con email y contraseña
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Enviar correo de verificación
      await sendEmailVerification(user);

      // Agregar usuario a Firebase
      const newData = {
        uid: user.uid,
        firstName,
        lastName,
        email,
        password,
        genre: "",
        rol: "user",
        addresses: [],
        since: serverTimestamp(),
      };

      await addDoc(collection(db, "users"), newData);

      // Cerrar sesión para evitar que el usuario no verificado inicie sesión
      await signOut(auth);

      navigate("/account/login", {
        state: {
          message:
            "Por favor verifica tu correo electrónico antes de iniciar sesión.",
        },
      });
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error en el registro", errorCode, errorMessage);
    }
  };

  return (
    <>
      <Helmet>
        <title>Registro - {website_name}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="login-page">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="block">
                <h2>Registro</h2>
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="Nombre"
                  {...register("firstName")}
                />
                <input
                  type="text"
                  placeholder="Apellido"
                  {...register("lastName")}
                />
              </div>
              <div className="block">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  {...register("email")}
                />
              </div>
              <div className="block">
                <input
                  type="password"
                  placeholder="Contraseña"
                  {...register("password")}
                />
              </div>
              <div className="block">
                <button type="submit">Registrarme</button>
              </div>
              <br />
              <div className="block">
                <Link to="/account/login">¿Ya tienes una cuenta?</Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default Register;
