import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { website_name } from "../../config/websiteConfig";
import { useAuth, useFans, useUsers } from "../../firebase/fbQueries";

function Newsletter() {
  const fans = useFans(db);
  const users = useUsers(db);
  const authUser = useAuth(db);
  const currUser = users.find((user) => user.email === authUser.email);
  const isFan = fans.find((fan) => fan.email === authUser.email);

  return (
    <>
      <Helmet>
        <title>Boletín - {website_name}</title>
      </Helmet>

      <div className="account-main-header">Boletín</div>
      {currUser && (
        <div className="account-main-content">
          {isFan ? (
            <>
              <form className="form">
                <div className="block">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, pariatur. Vel tempora quibusdam soluta eligendi sit
                    aliquid delectus reprehenderit praesentium cum blanditiis
                    quisquam nesciunt provident quas, labore ipsam
                    exercitationem vitae!
                  </p>
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="Nombre"
                    value={isFan.firstName}
                  />
                  <input
                    type="text"
                    placeholder="Apellido"
                    value={isFan.lastName}
                  />
                </div>
                <div className="block">
                  <input type="text" placeholder="País" value={isFan.country} />
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="Correo electrónico"
                    value={isFan.email}
                  />
                </div>
                <div className="block">
                  <input
                    type="text"
                    value={new Date(
                      isFan.since.seconds * 1000
                    ).toLocaleDateString("es-ES", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                    disabled
                  />
                </div>
                <div className="block">
                  <button type="submit">Darse de Baja</button>
                </div>
              </form>
            </>
          ) : (
            <>
              <form className="form">
                <div className="block">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Odit, pariatur. Vel tempora quibusdam soluta eligendi sit
                    aliquid delectus reprehenderit praesentium cum blanditiis
                    quisquam nesciunt provident quas, labore ipsam
                    exercitationem vitae!
                  </p>
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="Nombre"
                    value={isFan?.firstName}
                  />
                  <input
                    type="text"
                    placeholder="Apellido"
                    value={isFan?.lastName}
                  />
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="País"
                    value={isFan?.country}
                  />
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="Correo electrónico"
                    value={isFan?.email}
                  />
                </div>
                <div className="block">
                  <input
                    type="text"
                    value={new Date(
                      isFan?.since.seconds * 1000
                    ).toLocaleDateString("es-ES", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                      second: "numeric",
                    })}
                    disabled
                  />
                </div>
                <div className="block">
                  <button type="submit">Darse de Baja</button>
                </div>
              </form>
            </>
          )}
        </div>
      )}
    </>
  );
}
export default Newsletter;
