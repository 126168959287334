import NavMenu from "./NavMenu";

function Menu(props) {
  return (
    <div className={!props.menu ? "menu" : "menu show"}>
      {props.menu && (
        <>
          <div className="menu-header">
            <span>Menú de navegación</span>
          </div>
          <div className="menu-content">
            <NavMenu close={props.handleMenu} />
          </div>
        </>
      )}
    </div>
  );
}
export default Menu;
