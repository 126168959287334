import { Link } from "react-router-dom";

function NavRoutes(props) {
  return (
    <div className="block-nav">
      <Link to="/">Inicio</Link> /
      {props.category ? (
        <Link to="/collections">Colecciones</Link>
      ) : (
        <span>Colecciones</span>
      )}
      {props.category && (
        <>
          {" / "}
          <span>{props.category}</span>
        </>
      )}
      {props.title && (
        <>
          {" / "}
          <span>{props.title}</span>
        </>
      )}
    </div>
  );
}
export default NavRoutes;
