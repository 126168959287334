import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Copyright() {

    const year = new Date().getFullYear();

  return (
    <div className="copyright">
      <FontAwesomeIcon icon={faCopyright} size="sm" /> {year} Daniel Ospid - Todos Los Derechos Reservados.
    </div>
  );
}
export default Copyright;
