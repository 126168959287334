import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { auth, signInWithEmailAndPassword } from "../../firebase/config";
import { website_name } from "../../config/websiteConfig";

function Login() {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      if (user.emailVerified) {
        navigate("/account");
      } else {
        navigate("/account/check");
      }
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error al iniciar sesión", errorCode, errorMessage);
    }
  };

  return (
    <>
      <Helmet>
        <title>Inicio de Sesión - {website_name}</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="login-page">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="block">
                <h2>Inicio de Sesión</h2>
              </div>
              <div className="block">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  {...register("email")}
                />
              </div>
              <div className="block">
                <input
                  type="password"
                  placeholder="Contraseña"
                  {...register("password")}
                />
              </div>
              <div className="block">
                <button type="submit">Iniciar Sesión</button>
              </div>
              <br />
              <div className="block">
                <Link to="/account/register">¿No tienes una cuenta?</Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default Login;
