import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { faHeartCrack } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NoFound() {
  return (
    <>
      <Helmet>
        <title>Error 404 - {website_name}</title>
      </Helmet>
      <div className="no-found">
        <div className="container">
          <span className="title-no-found">404</span>
          <span className="icon-no-found">
            <FontAwesomeIcon icon={faHeartCrack} size="8x" />
          </span>
          <p className="text-no-found">
            La página que estas buscando no existe o se ha modificado.
          </p>
          <Link to="/" className="button primary-button">
            Volver al Inicio
          </Link>
        </div>
      </div>
    </>
  );
}
export default NoFound;
