import NewsletterForm from "./NewsletterForm";

function NewsLetter() {

  return (
    <div className="newsletter">
      <NewsletterForm />
    </div>
  );
}
export default NewsLetter;