import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faBars,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import {
  useCollectionMerch,
  useCollectionMusic,
  useNavCollections,
  useProducts,
} from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function NavSubMenu(props) {
  const products = useProducts(db);
  const collections = useNavCollections(db);
  const merch = useCollectionMerch(db);
  const music = useCollectionMusic(db);

  const countProductsByCategory = (category) => {
    return products.filter(
      (product) => product.category.includes(category) && product.show
    ).length;
  };

  return (
    <>
      <li className="nav-item">
        <button
          type="button"
          className="button icon-button"
          onClick={props.handleMenu}
          title="Menú de navegación"
          aria-label="Abrir menú de navegación"
        >
          <FontAwesomeIcon icon={!props.menu ? faBars : faClose} size="xl" />
        </button>
      </li>
      {/* Collections */}
      <li className="nav-item">
        <div className="nav-wrapper">
          <Link to="/collections" className="nav-link">
            Colecciones
            <FontAwesomeIcon icon={faAngleDown} size="sm" />
          </Link>
        </div>
        <ul className="nav-sub-list">
          {collections
            .sort((a, b) => a.order - b.order)
            .map(
              (collection, index) =>
                collection.show && (
                  <li key={index} className="nav-sub-item">
                    <Link
                      to={`/collections/${collection.path}`}
                      className="nav-sub-link"
                    >
                      {collection.title}
                    </Link>
                  </li>
                )
            )}
        </ul>
      </li>
      {/* Merch */}
      <li className="nav-item">
        <div className="nav-wrapper">
          <Link to="/collections/merch" className="nav-link">
            Ropa
            <FontAwesomeIcon icon={faAngleDown} />
          </Link>
        </div>
        <ul className="nav-sub-list">
          {merch
            .sort((a, b) => a.order - b.order)
            .map((merch, index) => (
              <li key={index} className="nav-sub-item">
                <Link
                  to={`/collections/${merch.path}`}
                  className="nav-sub-link"
                >
                  {merch.title}
                  <span className="nav-sub-count">
                    {countProductsByCategory(merch.path)}
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </li>
      {/* Music */}
      <li className="nav-item">
        <div className="nav-wrapper">
          <Link to="/collections/music" className="nav-link">
            Música
            <FontAwesomeIcon icon={faAngleDown} size="sm" />
          </Link>
        </div>
        <ul className="nav-sub-list">
          {music
            .sort((a, b) => a.order - b.order)
            .map((music, index) => (
              <li key={index} className="nav-sub-item">
                <Link
                  to={`/collections/${music.path}`}
                  className="nav-sub-link"
                >
                  {music.title}
                  <span className="nav-sub-count">
                    {countProductsByCategory(music.path)}
                  </span>
                </Link>
              </li>
            ))}
        </ul>
      </li>
    </>
  );
}
export default NavSubMenu;
