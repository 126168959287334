import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase/config";
import { useAuth, useUsers } from "../../firebase/fbQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

function Profile() {
  const users = useUsers(db);
  const authUser = useAuth(db);
  const currUser = users.find((user) => user.email === authUser.email);

  const { register, handleSubmit, setValue } = useForm();

  const [initialData, setInitialData] = useState({});

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDoc = doc(db, "users", user.uid);
        const userSnapshot = await getDoc(userDoc);

        if (userSnapshot.exists()) {
          const userData = userSnapshot.data();
          setInitialData(userData);

          // Set form values
          setValue("firstName", userData.firstName);
          setValue("lastName", userData.lastName);
          setValue("birthday", userData.birthday);
        } else {
          console.error("No such document!");
        }
      }
    };

    fetchUserData();
  }, [setValue]);

  const onSubmit = async (data) => {
    const user = auth.currentUser;

    if (user) {
      const userDoc = doc(db, "users", user.uid);
      const updatedData = {};

      // Compare data and update only the changed fields
      if (data.firstName !== initialData.firstName) {
        updatedData.firstName = data.firstName;
      }
      if (data.lastName !== initialData.lastName) {
        updatedData.lastName = data.lastName;
      }
      if (data.birthday !== initialData.birthday) {
        updatedData.birthday = data.birthday;
      }

      if (Object.keys(updatedData).length > 0) {
        try {
          await updateDoc(userDoc, updatedData);
          alert("Información actualizada correctamente");
        } catch (error) {
          console.error("Error updating document: ", error);
          alert("Error actualizando la información");
        }
      } else {
        alert("No hay cambios para actualizar");
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Perfil - {website_name}</title>
      </Helmet>

      <div className="account-main-header">Perfil</div>
      {currUser && (
        <div className="account-main-content">
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="block">
              <input
                type="text"
                placeholder="Nombre"
                defaultValue={currUser?.firstName}
                {...register("firstName")}
              />
              <input
                type="text"
                placeholder="Apellido"
                defaultValue={currUser?.lastName}
                {...register("lastName")}
              />
            </div>
            <div className="block">
              <input
                type="email"
                placeholder="Correo electrónico"
                value={currUser?.email}
                disabled
              />
            </div>
            <div className="block">
              <input
                type="date"
                defaultValue={
                  currUser.birthday ? (
                    new Date(currUser.birthday.seconds * 1000)
                      .toISOString()
                      .split("T")[0]
                  ) : (
                    <>...</>
                  )
                }
                placeholder="Cumpleaños (DD/MM/AAAA)"
                {...register("birthday")}
              />
            </div>
            <div className="block">
              <select {...register("genre")} defaultValue={currUser?.genre}>
                <option value="">Género</option>
                <option value="Hombre">Hombre</option>
                <option value="Mujer">Mujer</option>
              </select>
              <FontAwesomeIcon icon={faAngleDown} size="sm" />
            </div>
            <div className="block">
              <select
                {...register("preference")}
                defaultValue={currUser?.preference}
              >
                <option value="">Preferencia</option>
                <option value="Hetero">Hetero</option>
                <option value="Lesbiana">Lesbiana</option>
                <option value="Gay">Gay</option>
                <option value="Bisexual">Bisexual</option>
                <option value="Trans">Trans</option>
                <option value="lgbt+">LGBT+</option>
              </select>
              <FontAwesomeIcon icon={faAngleDown} size="sm" />
            </div>
            <div className="block">
              <button type="submit">Guardar Cambios</button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
export default Profile;
