import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useCollections } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import { Link } from "react-router-dom";
import NavRoutes from "../common/NavRoutes";

function CollectionsAll() {
  const collections = useCollections(db);

  return (
    <>
      <Helmet>
        <title>Colecciones - {website_name}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <NavRoutes />
          <div className="block-grid">
            {collections
              .sort((a, b) => a.order - b.order)
              .map(
                (collection, index) =>
                  collection.show && (
                    <Link
                      to={`/collections/${collection.path}`}
                      className="collection-card"
                      key={index}
                    >
                      {collection.image && (
                        <img src={collection.image} alt={collection.title} />
                      )}
                      <span>{collection.title}</span>
                    </Link>
                  )
              )}
          </div>
        </div>
      </section>
    </>
  );
}
export default CollectionsAll;
