import { Link } from "react-router-dom";
import { useContext } from "react";
import { CartContext } from "../../context/ShoppingCartContext";

function ProductCard(props) {
  const [cart, setCart] = useContext(CartContext);

  const addToCart = () => {
    setCart((currItems) => {
      const isItemsFound = currItems.find((item) => item.id === props.id);
      if (isItemsFound) {
        return currItems.map((item) => {
          if (item.id === props.id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      } else {
        return [
          ...currItems,
          {
            id: props.id,
            quantity: 1,
            img_def: props.img_def,
            title: props.title,
            price: props.price,
          },
        ];
      }
    });
  };

  return (
    <>
      {props.show && (
        <div className="product-card">
          <Link to={`/products/${props.path}`} className="product-card-link">
            <div className="product-card-figure">
              <>
                {props.presale && <span className="presale">Preventa</span>}
                {props.dto && <span className="dto">Descuento</span>}
                {props.sold && <span className="sold">Agotado</span>}
              </>
              <img
                src={props.img_def}
                alt={props.title}
                className="img_def"
                loading="lazy"
              />
              {props.img_hov && (
                <>
                  <div className="background"></div>
                  <img
                    src={props.img_hov}
                    alt={props.title}
                    className="img_hov"
                    loading="lazy"
                  />
                </>
              )}
              <div className="product-screen"></div>
            </div>
          </Link>
          <div className="product-card-details">
            <div className="product-card-title">
              <Link to={`/products/${props.path}`} title={props.title}>
                {props.title}
              </Link>
            </div>
            <div className="product-card-price">
              ${props.price.toFixed(2)}{" USD"}
            </div>
            <div className="product-card-action">
              {props.stock === 0 || props.sold ? (
                <button
                  type="button"
                  className="button sold-button disabled"
                  disabled
                >
                  Agotado
                </button>
              ) : props.presale ? (
                <button
                  type="button"
                  className="button presale-button"
                  onClick={() => addToCart()}
                >
                  Reservar
                </button>
              ) : (
                <button
                  type="button"
                  className="button add-button"
                  onClick={() => addToCart()}
                >
                  Agregar a la Bolsa
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductCard;
