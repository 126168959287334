import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { db } from "../../firebase/config";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function NewsletterForm() {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const q = query(collection(db, "fans"), where("email", "==", data.email));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        window.alert("¡Ya te has suscrito anteriormente al boletín de Daniel Ospid!");
        return reset();
      }

      const newData = {
        ...data,
        since: serverTimestamp(),
      };

      await addDoc(collection(db, "fans"), newData);
      window.alert("¡Te has suscrito exitosamente al boletín de Daniel Ospid!");
      return reset();
    } catch (error) {
      console.log(error);
      window.alert("¡No te has podido suscribir al boletín de Daniel Ospid!");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="newsletters-form">
      <div className="form-block" id="block-1">
        <span className="title">Siscribete, Mantente Actualizado</span>
      </div>
      <div className="form-block" id="block-2">
        <input
          type="text"
          className="input"
          placeholder="Nombre"
          {...register("firstName", {
            required: "El nombre es requerido.",
            pattern: {
              value: /^[A-Za-zÁÉÍÓÚáéíóúÑñÜü]+$/i,
              message: "El nombre no puede contener números.",
            },
          })}
        />
        <input
          type="text"
          className="input"
          placeholder="Apellido"
          {...register("lastName", {
            required: "El apellido es requerido.",
            pattern: {
              value: /^[A-Za-zÁÉÍÓÚáéíóúÑñÜü]+$/i,
              message: "El apellido no puede contener números.",
            },
          })}
        />
      </div>
      <div className="form-block" id="block-3">
        {errors.firstName && (
          <span className="alert">{errors.firstName.message}</span>
        )}
        {errors.lastName && (
          <span className="alert">{errors.lastName.message}</span>
        )}
      </div>
      <div className="form-block" id="block-4">
        <input
          type="text"
          className="input"
          placeholder="Escribe tu país"
          {...register("country", { required: "El país es requerido." })}
        />
      </div>
      {errors.country && (
        <div className="form-block" id="block-5">
          <span className="alert">{errors.country.message}</span>
        </div>
      )}
      <div className="form-block" id="block-6">
        <input
          type="email"
          className="input"
          placeholder="Escribe tu correo electrónico"
          {...register("email", {
            required: "El correo electrónico es requerido.",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "El correo electrónico no es válido.",
            },
          })}
        />
        <button type="submit" className="button primary-button">
          Suscribirme
        </button>
      </div>
      {errors.email && (
        <div className="form-block" id="block-7">
          <span className="alert">
            <FontAwesomeIcon />
            {errors.email.message}
          </span>
        </div>
      )}
      <div className="form-block" id="block-8">
        <span className="policy">
          He leído y acepto los{" "}
          <Link to="/policies/terms">
            Términos & Condiciones
          </Link>{" "}
          de la tienda en línea de daniel Ospid.
        </span>
      </div>
    </form>
  );
}
export default NewsletterForm;