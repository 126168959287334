import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useProducts } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";
import ProductCard from "../common/ProductCard";
import NavRoutes from "../common/NavRoutes";

function Music() {
  const category = "music";
  const products = useProducts(db);
  const sameCategory = products.filter((product) =>
    product.category.includes(category)
  );
  return (
    <>
      <Helmet>
        <title>Música - {website_name}</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <NavRoutes category="Música" />
          <div className="block-header">
            <h2>Música</h2>
          </div>
          <div className="block-grid">
            {sameCategory
              .sort((a, b) => a.id - b.id)
              .map((product, index) => (
                <ProductCard key={index} {...product} />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default Music;
