import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useForm } from "react-hook-form";
import { useAuth } from "../../firebase/fbQueries";

function Login(props) {
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      var user = userCredential.user;
      console.log("Inicio de sesión exitoso", user);

      props.setLogin(false);
      props.setGlass(false);
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error al iniciar sesión", errorCode, errorMessage);
    }
  };

  const authUser = useAuth();

  const auth = getAuth();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  return (
    <div className={!props.login ? "login" : "login show"}>
      {!authUser && (
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="block">
            <span className="title">Login</span>
          </div>
          <div className="block">
            <input
              type="email"
              placeholder="Correo electrónico"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
            />
          </div>
          <div className="block">
            <input
              type="password"
              placeholder="Contraseña"
              {...register("password", { required: true, minLength: 8 })}
            />
          </div>
          <div className="block">
            <button type="submit" className="button login-button">
              Ingresar
            </button>
          </div>
        </form>
      )}
      {authUser && (
        <div className="form">
          <div className="block">
            <div className="title">Sesión Iniciada</div>
          </div>
          <div className="block">
            <button
              type="button"
              className="button login-button"
              onClick={() => handleSignOut()}
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
export default Login;
