import { Helmet } from "react-helmet";
import { website_name } from "../../config/websiteConfig";
import { useState } from "react";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../firebase/config";

function Check() {
  const [message, setMessage] = useState("");

  const resendVerificationEmail = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        await sendEmailVerification(user);
        setMessage(
          "Correo de verificación enviado. Por favor, revisa tu bandeja de entrada."
        );
      } catch (error) {
        console.error("Error al enviar el correo de verificación", error);
        setMessage(
          "Hubo un error al enviar el correo de verificación. Por favor, inténtalo de nuevo."
        );
      }
    } else {
      setMessage(
        "No se pudo encontrar el usuario. Por favor, inicia sesión nuevamente."
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>Verificar Usuario - {website_name}</title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="login-page">
            <h2>Verificar Usuario</h2>
            <p>Por favor, verifica tu correo electrónico para continuar.</p>
            <button onClick={resendVerificationEmail}>
              Reenviar correo de verificación
            </button>
            <br />
            {message && <p className="red">{message}</p>}
          </div>
        </div>
      </section>
    </>
  );
}

export default Check;
